export default class RepeatRequest {
  interval = null;

  repeat(timeInterval = 5000, callback = () => {}) {
    this.interval = setInterval(() => {
      callback()
    }, timeInterval)
  }

  stopInterval() {
    clearInterval(this.interval)
  }
}
